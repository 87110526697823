module.exports = [{
      plugin: require('/home/tiago/dev/juntos/campaigns/psicologia/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-266727-14"},
    },{
      plugin: require('/home/tiago/dev/juntos/campaigns/psicologia/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('/home/tiago/dev/juntos/campaigns/psicologia/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
